<template>
  <div>
    <PpModal v-model="open">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="topUpForm"
            :model="topUpForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top">
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                Top up
              </h3>
            </div>
            <div class="column is-12">
              <p>How much would you like to top up?</p>
            </div>
            <el-form-item
              class="column is-narrow"
              prop="amount">
              <div class="el-input-number-wrapper">
                <el-input-number
                  v-model.trim="topUpForm.amount"
                  :min="0"
                  :controls="false"
                  placeholder="Amount" />
                <span class="hack-input-number-suffix">{{ currency }}</span>
              </div>
            </el-form-item>
            <el-form-item
              class="column is-12"
              prop="remark">
              <el-input
                v-model="topUpForm.remark"
                :rows="3"
                type="textarea"
                placeholder="remark" />
            </el-form-item>

            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSave">
                Top up
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <PpModal v-model="confirmModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="confirmForm"
            :model="confirmForm"
            :rules="confirmFormRules"
            class="columns is-multiline"
            label-position="top">
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                Confirm Top up
              </h3>
            </div>
            <div class="column is-12">
              <p class="m-b">
                You are about to top up to {{ companyName }}. Are you sure you want to proceed?
              </p>
              <p class="m-b">
                <b class="has-text-primary">Top up amount:</b> {{ topUpForm.amount|currency({currency:currency}) }}
              </p>
              <p><b class="has-text-primary">Remark:</b><br> {{ topUpForm.remark }}</p>
            </div>
            <el-form-item
              class="column is-12"
              prop="password"
              label="Please enter your password to proceed">
              <el-input
                v-model.trim="confirmForm.password"
                type="password"
                placeholder="password" />
            </el-form-item>

            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleConfirmCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleConfirm">
                Top up
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
export default {
  name: 'TopUpModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    companyName: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: 'USD'
    },
    remark: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      confirmModalActive: false,
      topUpForm: {
        amount: this.amount,
        remark: this.remark
      },
      rules: {
        amount: [
          {
            required: true,
            message: 'Amount is required.',
            trigger: [
              'blur'
            ]
          }
        ]
      },
      confirmFormRules: {
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change',
              'blur'
            ]
          },
          {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ]
      },
      confirmForm: {
        password: undefined
      }
    }
  },
  watch: {
    amount (nV) {
      this.topUpForm.amount = nV
    },
    remark (nV) {
      this.topUpForm.remark = nV
    }
  },
  methods: {
    handleSave () {
      this.$refs.topUpForm.validate((valid) => {
        if (valid) {
          this.$emit('update:open', false)
          this.confirmModalActive = true
          return true
        } else {
          return false
        }
      })
    },
    handleCancel () {
      this.$refs.topUpForm.resetFields()
      this.$emit('update:open', false)
    },
    handleConfirm () {
      this.$refs.confirmForm.validate((valid) => {
        if (valid) {
          this.$emit('confirm', {
            ...this.topUpForm,
            ...this.confirmForm
          })
          this.handleConfirmCancel()
        } else {
          // todo
          return false
        }
      })
    },
    handleConfirmCancel () {
      // this.topUpForm.amount = undefined
      // this.topUpForm.remark = undefined
      this.$refs.confirmForm.resetFields()
      this.confirmModalActive = false
    }
  }
}
</script>

<style scoped>

</style>
