<template>
  <div class="pagination-wrapper">
    <div class="columns pagi-con is-mobile is-multiline is-gapless">
      <div class="column is-3-mobile">
        <a
          class="is-size-7"
          @click.prevent="scrollTo">{{ $t('views.SearchManyPagination.back-to-top') }}</a>
      </div>
      <div
        v-if="totalItems"
        class="column has-text-right">
        <span class="is-size-7">
          {{ $t('views.SearchManyPagination.showing-results') }} {{ showingNow }}
        </span>
      </div>
      <div class="column is-12 is-hidden-tablet">
        <PpGap />
      </div>
      <div class="column is-12-mobile is-narrow has-text-centered-mobile has-text-right">
        <el-pagination
          v-if="totalItems"
          class="pagi"
          background
          layout="sizes, prev, pager, next"
          :pager-count="pagerCount"
          :page-size="localSize"
          :page-sizes="[10, 30, 50, 100]"
          :current-page="localPage"
          :total="totalItems"
          @size-change="handleSizeChange"
          @current-change="handlePageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import smoothScroll from '@/plugins/smoothScroll'
export default {
  name: 'Pagination',
  props: {
    totalItems: {
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 30
    },
    pagerCount: {
      type: Number,
      default: 5
    },
    topElement: {
      type: String,
      default: '#app'
    }
  },
  data () {
    return {
      localPage: this.page,
      localSize: this.pageSize
    }
  },
  computed: {
    showingNow () {
      return `${this.localSize * (this.localPage - 1) + 1} – ${Math.min(this.localPage * this.localSize, this.totalItems)} of ${this.totalItems}`
    }
  },
  watch: {
    'page': {
      immediate: true,
      handler (nVal) {
        this.localPage = nVal
      }
    }
  },
  methods: {
    handlePageChange (pg) {
      this.localPage = pg
      this.$emit('change', { page: pg, limit: this.localSize })
    },
    handleSizeChange (size) {
      this.localSize = size
      this.$emit('change', { page: 1, limit: size })
    },
    scrollTo () {
      smoothScroll.scrollTo(this.topElement, { duration: 500 })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/bulma-variables";

  .pagi-con {
    align-items: center;
  }

  .pagi {
    padding: 0 !important;
  }

  .pagination-wrapper {
    padding: $bleed*10 0;
  }
</style>
