<template>
  <div>
    <div v-if="walletCurrency==='USD'">
      <p><strong class="has-text-secondary">USD bank transfers:</strong></p>
      <table class="bank-info m-b-4x">
        <tr>
          <th>ACCOUNT NAME:</th>
          <td>VIVA CONNECT (PTY) LTD</td>
        </tr>
        <tr>
          <th>ACCOUNT NUMBER:</th>
          <td>62858870035</td>
        </tr>
        <tr>
          <th>SWIFT CODE:</th>
          <td>FIRNZAJJXXX</td>
        </tr>
        <tr>
          <th>BANK NAME:</th>
          <td>First National Bank</td>
        </tr>
        <tr>
          <th>BANK ADDRESS:</th>
          <td>FNB Forex, 30 Diagonal Street, FNB Place, 9th Floor, Johannesburg, 2001</td>
        </tr>
        <tr>
          <th>BRANCH CODE:</th>
          <td>254655</td>
        </tr>
      </table>
    </div>
    <div v-if="walletCurrency==='ZAR'">
      <p><strong class="has-text-secondary">ZAR bank transfers:</strong></p>
      <table class="bank-info">
        <tr>
          <th>ACCOUNT NAME:</th>
          <td>VIVA CONNECT (PTY) LTD</td>
        </tr>
        <tr>
          <th>ACCOUNT NUMBER:</th>
          <td>62769884894</td>
        </tr>
        <tr>
          <th>SWIFT CODE:</th>
          <td>FIRNZAJJXXX</td>
        </tr>
        <tr>
          <th>BANK NAME:</th>
          <td>First National Bank</td>
        </tr>
        <tr>
          <th>BANK ADDRESS:</th>
          <td>Thebe Hoskins House, Breda St & Mill Street, Gardens, Cape Town, 8001</td>
        </tr>
        <tr>
          <th>BRANCH CODE:</th>
          <td>201511</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BankInfo',
  props: {
    walletCurrency: {
      type: String,
      default: 'USD'
    }
  }
}
</script>

<style  lang="scss" scoped>
  .bank-info {
    th {
      width: 11em;
    }
  }
</style>
<style  lang="scss">
  .bank-info {
    th {
      width: 11em;
    }
  }
</style>
