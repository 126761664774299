<template>
  <div class="section p-l-0 p-r-0">
    <div class="container is-fluid">
      <h1 class="is-size-3 has-text-primary has-text-weight-bold m-b-4x">
        Top up requests
      </h1>
      <el-form
        ref="localForm"
        :model="localForm"
        class="columns is-mini-gap is-multiline"
        status-icon
        @submit.native.prevent="">
        <el-form-item
          class="column is-narrow"
          prop="search">
          <el-input
            v-model="localForm.search"
            type="text"
            autocomplete="search"
            placeholder="search company name or email"
            style="min-width: 320px;"
            @input="delaySearch" />
        </el-form-item>
        <el-form-item
          class="column is-narrow"
          prop="status">
          <el-select
            v-model="localForm.status"
            placeholder="Status"
            @change="delaySearch">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
        <div class="column is-narrow">
          <el-button
            v-if="hasSearchParams"
            class="button-secondary-outline m-r-3x"
            style="min-width: 100px;"
            @click="clear">
            CLEAR
          </el-button>
        </div>
        <div class="column">
          <el-button
            class="is-pulled-right"
            type="primary"
            @click="handleExport">
            Export to Excel
          </el-button>
        </div>
      </el-form>
      <el-table
        ref="singleTable"
        :data="items"
        cell-class-name="top-request-cell"
        stripe
        style="width: 100%">
        <el-table-column
          property="createdAt"
          label="Timestamp (localtime)"
          sortable
          width="190">
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.createdAt) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          property="companyName"
          label="Company name"
          width="170"
          sortable />
        <el-table-column
          property="email"
          label="Email"
          width="170"
          sortable />
        <el-table-column
          property="status"
          label="Status"
          sortable>
          <template slot-scope="scope">
            <span :class="`${scope.row.status === 'pending' ? 'has-text-danger' : 'has-text-success'}`">{{ scope.row.status | statusLabel }}</span>
          </template>
        </el-table-column>
        <el-table-column
          property="accountName"
          label="Account name"
          width="150"
          sortable />
        <el-table-column
          label="Transferred amount"
          align="right"
          sortable
          width="180">
          <template slot-scope="scope">
            {{ scope.row.amount / 100 | number(2) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Currency"
          sortable>
          <template slot-scope="scope">
            <el-tooltip
              placement="bottom"
              effect="light">
              <BankInfo
                slot="content"
                :wallet-currency="scope.row.currency" />
              <el-link type="info">
                {{ scope.row.currency }}
              </el-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="Transfer slip"
          sortable>
          <template slot-scope="scope">
            <a
              class="has-text-secondary"
              @click.prevent="handleDownload(scope.row.transferSlip)">download</a>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <a
              v-if="scope.row.status==='pending'"
              class="has-text-secondary has-text-weight-bold"
              @click.prevent="handleOpenTopUp(scope)">TOP UP</a>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-if="totalItems > 10"
        :total-items="totalItems"
        :page="query.page"
        :page-size="10"
        :pager-count="5"
        top-element="#app"
        @change="handlePageChange" />

      <!-- topup modal -->
      <TopUpModal
        :open.sync="topUpModalActive"
        :company-name="selectedItem.companyName"
        :amount="selectedItem.amount / 100"
        :currency="selectedItem.currency"
        @confirm="handleConfirmTopUp" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import Pagination from '@/components/Pagination'
import TopUpModal from '@/views/admin/components/TopUpModal'
import { download } from '@/plugins/util'
import debounce from 'lodash/debounce'
import BankInfo from '../../components/BankInfo'

export default {
  name: 'TopUpRequests',
  components: { BankInfo, TopUpModal, Pagination },
  filters: {
    statusLabel (value) {
      switch (value) {
      case 'pending':
        return 'Pending'
      case 'topped_up':
        return 'Topped up'
      }
    }
  },
  data () {
    return {
      localForm: {
        search: undefined,
        status: undefined
      },
      selectedItem: {},
      query: {
        page: 1,
        limit: 10
      },
      isFetching: false,
      items: [],
      totalItems: 0,
      statusOptions: [
        {
          label: 'Show all',
          value: undefined
        },
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'Topped up',
          value: 'topped_up'
        }
      ],
      topUpModalActive: false,
      companyName: undefined
    }
  },
  computed: {
    hasSearchParams () {
      return !!this.localForm.search || !!this.localForm.status
    }
  },
  created () {
    this.fetchData(this.query)
  },
  methods: {
    formatDate (date) {
      return moment(date).tz('Asia/Singapore').format('MMM DD, YYYY HH:mm')
    },
    handleExport () {
      this.isLoading = true
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('admin/exportTopUpRequests', this.localForm).then(({ data, headers }) => {
        download(data, headers)
        this.isLoading = false
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      }).catch((err) => {
        this.isLoading = false
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    handleDownload (fileName) {
      this.$store.dispatch('admin/downloadFile', fileName)
        .then(({ data, headers }) => {
          download(data, headers)
        }).catch((err) => {
          this.$store.commit('SET_GLOBAL_MODAL', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        })
    },
    handleOpenTopUp (scope) {
      this.topUpModalActive = true
      this.selectedItem = scope.row
    },
    handleConfirmTopUp (data) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch(
        'admin/confirmTopup',
        { ...data, id: this.selectedItem.id, appCode: this.selectedItem.appCode, currency: this.selectedItem.currency }
      ).then(({ outlets }) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (outlets.ok) {
          this.$message({
            message: 'Balance has been updated.',
            type: 'success'
          })
          this.fetchData(this.query)
        }
      }).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$message.error(err.message || (err.error && err.error.message) || '')
      })
    },
    handleSubmit () {
      this.query.page = 1
      this.fetchData({ ...this.query, ...this.localForm })
    },
    handlePageChange ({ page }) {
      this.query.page = page
      this.fetchData(this.query)
    },
    handleSortChanged ({ column, prop, order }) {
      const orderType = {
        ascending: 'ASC',
        descending: 'DESC'
      }
      this.localForm.sort = prop == null ? '' : `"${prop}",${orderType[order] ? orderType[order] : 'ASC'}`
      this.fetchHistory(1, this.sanitizeQuery(this.localForm))
    },
    fetchData (params) {
      this.isFetching = true
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('admin/getTopUpRequests', params).then(
        ({ outlets }) => {
          this.items = outlets.items
          this.totalItems = outlets.totalItems
          this.isFetching = false
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
        }
      ).catch(err => {
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          title: err.message || (err.error && err.error.message) || '',
          content: ''
        })
        this.isFetching = false
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      })
    },
    delaySearch: debounce(function () {
      this.handleSubmit()
    }, 500),
    clear () {
      this.localForm = {
        search: undefined,
        status: undefined
      }
      this.handleSubmit()
    }
  },
  metaInfo () {
    return {
      title: 'Top Up Request'
    }
  }
}
</script>
<style lang="scss">
  .top-request-cell {
    cursor: default !important;
  }
</style>
